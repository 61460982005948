// DATACENTER URLS

import client from "backend";

const createCRUDFunctions = (modelName, customerCode = null) => {
  // Base URL, optionally including customerCode
  const baseUrl = customerCode
    ? `/${customerCode}/${modelName}`
    : `/${modelName}`;

  console.log(customerCode, baseUrl);

  const getColumns = async () => {
    try {
      const response = await client.get(`${baseUrl}/columns`);
      console.log("Get All Columns:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching columns for ${modelName}:`, error);
      throw error;
    }
  };

  const getAllItems = async ({ limit = 10, offset = 0 } = {}) => {
    try {
      const response = await client.get(`${baseUrl}/`, {
        params: { limit, offset },
      });
      console.log("Get All Items:", response.data);

      return {
        items: response.data.items,
        total: parseInt(response.data.total, 10),
      };
    } catch (error) {
      console.error(`Error fetching items for ${modelName}:`, error);
      throw error;
    }
  };

  const addItem = async (item) => {
    try {
      const response = await client.post(`${baseUrl}/`, item);
      console.log("Added Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error adding item to ${modelName}:`, error);
      throw error;
    }
  };

  const updateItem = async (itemId, updatedItem) => {
    try {
      const response = await client.put(`${baseUrl}/${itemId}`, updatedItem);
      console.log("Updated Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error updating item in ${modelName}:`, error);
      throw error;
    }
  };

  const deleteItem = async (itemId) => {
    try {
      const response = await client.delete(`${baseUrl}/${itemId}`);
      console.log("Deleted Item:", response.data);
      return response.data;
    } catch (error) {
      console.error(`Error deleting item from ${modelName}:`, error);
      throw error;
    }
  };

  return {
    getColumns,
    getAllItems,
    addItem,
    updateItem,
    deleteItem,
  };
};

export default createCRUDFunctions;
