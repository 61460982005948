import React from "react";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";
import { Link } from "react-router-dom";

export const Sessions = ({ customerCode }) => {
  const SessionsPage = createCRUDPage(
    "session-crud",
    "Datacenter Sessions",
    customerCode,
    [
      {
        title: "ID",
        dataIndex: "id",
        render: (text, record) => (
          <Link to={`/datacenter-session/${text}`}>{text}</Link>
        ),
      },
    ]
  );

  return <SessionsPage />;
};
