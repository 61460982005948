import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";
import { dropVenues } from "./DataCenterAPIClient";
import React from "react";

// dropVenues();
const Venues = createCRUDPage(
  "venue-crud",
  "Datacenter Venues",
  null,
  {},
  {
    photo_url: {},
    working_hours: {},
    about: {},
    popular_times: {},
  }
);

export default Venues;
