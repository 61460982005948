import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Pagination } from "antd";
import { get } from "lodash";

const CRUDComponent = ({
  modelName,
  createCRUDFunctions,
  readOnly = false,
  updateItself = false,
  dataChangedNotifier = () => {},
  specialRenderedComponents = {},
  doNotDisplayComponents = {},
  customerCode = null,
}) => {
  const { getColumns, getAllItems, addItem, updateItem, deleteItem } =
    createCRUDFunctions(modelName, customerCode);

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedItem, setSelectedItem] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchColumns();
    fetchData(currentPage, pageSize);
  }, [modelName, updateItself, currentPage, pageSize]);

  const fetchColumns = async () => {
    try {
      const data = await getColumns();
      const mappedColumns = data
        .filter((column) => !doNotDisplayComponents[column])
        .map((column) => {
          if (!specialRenderedComponents[column]) {
            return {
              title: column,
              dataIndex: column,
              key: column,
            };
          } else {
            return {
              title: column,
              dataIndex: column,
              key: column,
              render: (text, record) => {
                return specialRenderedComponents[column](text, record);
              },
            };
          }
        });

      setColumns(
        !readOnly
          ? [
              ...mappedColumns,
              {
                title: "Action",
                key: "action",
                render: (text, record) => (
                  <span>
                    <Button
                      onClick={() => showEditModal(record)}
                      style={{ margin: "3px" }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => showDeleteConfirm(record.id)}
                      danger
                      style={{ margin: "3px" }}
                    >
                      Delete
                    </Button>
                  </span>
                ),
              },
            ]
          : [...mappedColumns]
      );
    } catch (error) {
      console.error("Error fetching columns:", error);
    }
  };

  const fetchData = async (page, size) => {
    try {
      const data = await getAllItems({
        limit: size,
        offset: (page - 1) * size,
      });
      console.log("getAllItems", data);
      const total = data.total || 0; // Assuming your API returns total count
      setTotalItems(total);
      setItems(data.items?.map((value) => ({ key: value.id, ...value })));
    } catch (error) {
      console.error(`Error fetching ${modelName} data:`, error);
    }
  };

  const handleAdd = async (values) => {
    try {
      const newItem = await addItem(values);
      setItems([...items, { key: newItem.id, ...newItem }]);
      form.resetFields();
      setIsModalVisible(false);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  const handleUpdate = async (values) => {
    if (!selectedItem) return;
    try {
      const updatedItem = await updateItem(selectedItem.id, values);
      const updatedItems = items.map((item) =>
        item.id === updatedItem.id
          ? { key: updatedItem.id, ...updatedItem }
          : item
      );
      setItems(updatedItems);
      form.resetFields();
      setIsModalVisible(false);
      setSelectedItem(null);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const showDeleteConfirm = (itemId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await handleDelete(itemId);
      },
    });
  };

  const handleDelete = async (itemId) => {
    try {
      await deleteItem(itemId);
      setItems(items.filter((item) => item.id !== itemId));
      fetchData(currentPage, pageSize);
      dataChangedNotifier();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const showEditModal = (record) => {
    setSelectedItem(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const showAddModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  return (
    <div>
      <Table
        dataSource={items}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalItems}
        onChange={handlePageChange}
        style={{ marginBottom: 16 }}
      />
      {!readOnly && (
        <Button
          onClick={showAddModal}
          type="primary"
          style={{ marginBottom: 16 }}
        >
          Add
        </Button>
      )}

      <Modal
        title={selectedItem ? `Edit ${modelName}` : `Add ${modelName}`}
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form form={form} onFinish={selectedItem ? handleUpdate : handleAdd}>
          {columns
            .filter((column) => column.key !== "action")
            .map((column) => (
              <Form.Item
                key={column.key}
                name={column.dataIndex}
                label={column.title}
                rules={[
                  { required: true, message: `Please input ${column.title}!` },
                ]}
              >
                <Input />
              </Form.Item>
            ))}
        </Form>
      </Modal>
    </div>
  );
};

export default CRUDComponent;
