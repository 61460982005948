import React from "react";

import { Col, Row } from "antd";
import usecustomStyles from "../../Common/customStyles";
import styled from "styled-components";

const ContentHeader = (props) => {
  const customStyles = usecustomStyles();
  const BgInfo = styled.div`
    background-color: ${({ theme }) => theme.token.contactBg};
  `;
  const TextLight = styled.div`
    color: ${({ theme }) => theme.token.textLight};
  `;

  return (
    <>
      <Row gutter={[16]}>
        <Col xs={24}>
          <BgInfo
            style={{
              borderRadius: "0px",
              marginLeft: "-20px",
              marginRight: "-20px",
              padding: "40px 22px",
            }}
          >
            <div>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                gutter={[20, 20]}
              >
                <Col xs={24} lg={8}>
                  <TextLight
                    style={{ fontSize: customStyles.h4, fontWeight: "600" }}
                  >
                    {props.headerTitle}
                  </TextLight>
                </Col>
              </Row>
            </div>
          </BgInfo>
        </Col>
      </Row>
    </>
  );
};

export default ContentHeader;
