import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Input, Button, Checkbox, Row, Col, Alert } from "antd";
import usecustomStyles from "../../Common/customStyles";
import ParticleAuth from "../../Common/ParticleAuth";
import { useFormik } from "formik";
import styled from "styled-components";
import { userLogin } from "../Apps/Datacenter/DataCenterAPIClient";
import { setAuthorization } from "../../TheDoor/setTokenInterceptor";

// actions
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import withRouter from "../../Common/withRouter";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { loginSuccess } from "slices/auth/login/reducer";

const customStyles = usecustomStyles();

const StyleWrapper = styled.div`
  background-color: ${({ theme }) => theme.token.authbgcolor};
`;

const Signin = (props) => {
  // page title
  document.title = "Sign In";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies([
    "datacenter_access_token",
    "datacenter_refresh_token",
    "authUser",
  ]);
  const [isLoading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [error, setError] = useState("");

  const onSubmitted = async (values) => {
    setLoading(true);

    let response = await userLogin(values);
    console.log(response);

    if (response.status === 200) {
      dispatch(loginSuccess());
      setUserInfo(response?.data);
      console.log(response?.data);
      let expires = new Date();
      expires.setTime(expires.getTime() + 60 * 60 * 24 * 1000);
      setCookie("datacenter_access_token", response?.data?.access_token, {
        path: "/",
        expires,
      });
      setCookie("datacenter_refresh_token", response?.data?.refresh_token, {
        path: "/",
        expires,
      });
      setCookie("authUser", values, {
        path: "/",
        expires,
      });
      navigate("/dashboard");
    } else {
      console.log(response.statusText);
      setError(response?.statusText);
    }
    setLoading(false);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      // @ts-ignore
      email: userInfo?.email || "" || "",
      // @ts-ignore
      password: userInfo?.password || "<PASSWORD>" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      onSubmitted(values);
    },
  });

  return (
    <React.Fragment>
      <StyleWrapper className="auth-page-wrapper">
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col xs={24} lg={14}>
            <Card>
              <Row gutter={[16, 24]}>
                <ParticleAuth />
                <Col xs={24} lg={12}>
                  <div style={{ border: "0px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                      <h5
                        style={{
                          fontSize: "20px",
                          color: customStyles.colorPrimary,
                        }}
                      >
                        Welcome Back!
                      </h5>
                      <p>Sign in to continue to THE DOOR</p>
                    </div>
                    <div style={{ marginTop: "30px", padding: "90px" }}>
                      {error && error ? (
                        <Alert type="error" message={error}></Alert>
                      ) : null}
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div>
                          <label
                            style={{ display: "block", marginBottom: "4px" }}
                          >
                            Username
                          </label>
                          <Input
                            name="email"
                            style={{
                              margin: "10px 0px",
                              boxShadow: "none",
                              outline: "none",
                            }}
                            type="email"
                            value={validation.values.email || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            onInvalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? validation.touched.email
                                : undefined
                            }
                            status={
                              validation.touched.email &&
                              validation.errors.email
                                ? "error"
                                : true
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <p style={{ color: customStyles.colorDanger }}>
                                {validation.errors.email}
                              </p>
                            )}
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              style={{ display: "block", marginBottom: "4px" }}
                            >
                              Password
                            </label>
                            <Link
                              to="/forgot-password"
                              style={{ fontSize: "11px" }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <Input.Password
                            name="password"
                            value={validation.values.password || ""}
                            onChange={validation.handleChange}
                            style={{ boxShadow: "none", outline: "none" }}
                            onBlur={validation.handleBlur}
                            onInvalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? validation.touched.password
                                : undefined
                            }
                            status={
                              validation.touched.password &&
                              validation.errors.password
                                ? "error"
                                : true
                            }
                          />
                          {validation.touched.password &&
                            validation.errors.password && (
                              <p style={{ color: customStyles.colorDanger }}>
                                {validation.errors.password}
                              </p>
                            )}
                        </div>

                        <Form.Item>
                          <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                          >
                            <Checkbox style={{ marginTop: "10px" }}>
                              Remember me
                            </Checkbox>
                          </Form.Item>
                        </Form.Item>
                        <div>
                          <Button
                            htmlType="submit"
                            type="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            style={{ width: "100%" }}
                            loading={isLoading}
                          >
                            {" "}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </StyleWrapper>
    </React.Fragment>
  );
};

export default withRouter(Signin);
