import React from "react";
import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

const WifiMapsPage = ({ customerCode }) => {
  // Create the component using createCRUDPage with the provided customerCode
  const WifiMaps = createCRUDPage(
    "wifimap-crud",
    "Datacenter WifiMaps",
    customerCode
  );

  // Render the dynamically created component
  return <WifiMaps />;
};

export default WifiMapsPage;
