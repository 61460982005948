import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "leaflet-draw";

import { MapPin } from "lucide-react";

// Function to create a colored SVG icon
const createIcon = (icon) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      ${icon}
    </svg>
  `;
  const url = `data:image/svg+xml;base64,${btoa(svg)}`;

  return new L.Icon({
    iconUrl: url,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -12],
  });
};

// SVG path for the MapPin icon
const mapPinPath =
  '<path d="M12 2a6 6 0 0 1 6 6c0 3-6 10-6 10S6 11 6 8a6 6 0 0 1 6-6zm0 2a4 4 0 0 0-4 4c0 1.105.48 2.287 1.31 3.323L12 18l4.69-6.677C15.52 8.287 16 7.105 16 6a4 4 0 0 0-4-4z"/>'; // Path from MapPin

// Component to fit map bounds
const FitBounds = ({ bounds }) => {
  const map = useMap();
  React.useEffect(() => {
    if (bounds.length) {
      const leafletBounds = L.latLngBounds(bounds);
      map.fitBounds(leafletBounds);
    }
  }, [bounds, map]);

  return null;
};

// Component to handle drawing of geofences
const DrawGeofence = ({ onGeofenceCreated }) => {
  const map = useMap();

  useEffect(() => {
    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      //   edit: {
      //     featureGroup: drawnItems,
      //   },
      draw: {
        marker: false, // Disable marker drawing
        circlemarker: false, // Disable circlemarker drawing
        polygon: false,
        polyline: false,
        rectangle: false,
        circle: {
          shapeOptions: {
            color: "yellow",
            fillOpacity: 0.3,
          },
        },
      },
    });
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      const type = event.layerType;
      if (type === "circle") {
        const radius = layer.getRadius();
        const center = layer.getLatLng();

        // Call the callback function with new geofence data
        onGeofenceCreated({
          latitude: center.lat,
          longitude: center.lng,
          radius,
          customer_code: "thedoor", // Placeholder
          geofence_type: "new_type", // Placeholder
        });
      } else {
        // Handle other types of layers if necessary
        console.log(`Layer type ${type} is not supported.`);
      }

      drawnItems.addLayer(layer);
    });

    return () => {
      map.removeControl(drawControl);
    };
  }, [map, onGeofenceCreated]);

  return null;
};

const GeofenceMapComponent = ({ geofences, addGeofence }) => {
  // Calculate map center
  const center = geofences.length
    ? [
        geofences.reduce((sum, gf) => sum + gf.latitude, 0) / geofences.length,
        geofences.reduce((sum, gf) => sum + gf.longitude, 0) / geofences.length,
      ]
    : [0, 0];

  // Calculate bounds
  const bounds = geofences?.map((gf) => [gf.latitude, gf.longitude]);

  return (
    <MapContainer
      center={center}
      zoom={1}
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {geofences?.map((gf) => (
        <React.Fragment key={gf.id}>
          <Circle
            center={[gf.latitude, gf.longitude]}
            radius={gf.radius}
            pathOptions={{ color: "blue", fillColor: "blue", fillOpacity: 0.3 }}
          >
            <Popup>
              <div>
                <strong>Customer Code:</strong> {gf.customer_code}
                <br />
                <strong>Geofence Type:</strong> {gf.geofence_type}
                <br />
                <strong>Radius:</strong> {gf.radius} meters
              </div>
            </Popup>
          </Circle>
          <Marker
            position={[gf.latitude, gf.longitude]}
            icon={createIcon(mapPinPath)}
          >
            <Popup>
              <div>
                <strong>Customer Code:</strong> {gf.customer_code}
                <br />
                <strong>Geofence Type:</strong> {gf.geofence_type}
                <br />
                <strong>Radius:</strong> {gf.radius} meters
              </div>
            </Popup>
          </Marker>
        </React.Fragment>
      ))}
      <FitBounds bounds={bounds} />
      <DrawGeofence onGeofenceCreated={addGeofence} />
    </MapContainer>
  );
};

export default GeofenceMapComponent;
