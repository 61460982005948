import React from 'react'

import logoLightFull from "../assets/images/logo-light.png";
import lg from "../assets/images/lg.png"
import authEffect2 from "../assets/images/effect-pattern/auth-effect-2.png";
import authEffect from "../assets/images/effect-pattern/auth-effect.png";
import usecustomStyles from './customStyles';
import { HeartFilled } from '@ant-design/icons';
import { Col } from 'antd';

const customStyles = usecustomStyles();

const ParticleAuth = () => {
    return (
        <React.Fragment>
                <Col xs={24} lg={10} style={{ backgroundColor: customStyles.colorPrimary, color: customStyles.colorBgContainer, borderRadius: '6px' }}>
                    <div style={{ display: 'flex', flexDirection: "column", alignItems: "space-between", padding: "20px", justifyContent:'space-between', height:'100%' }}>
                        <div style={{ marginBottom: "30px" }}>
                            <img src={lg} alt=""/>
                            <img src={authEffect2} alt="" style={{ position: "absolute", zIndex: "1", top: '30%', right: "-60%", transform: 'rotate(-0deg)' }} />
                            {/* <img src={authEffect} alt="" style={{ position: "absolute", zIndex: "1", top: "50%", right: "15%", transform: 'rotate(-45deg)' }} />
                            <img src={authEffect} alt="" style={{ position: "absolute", zIndex: "1", bottom: "-15px", right: "20px" }} /> */}
                            <img src={authEffect2} alt="" style={{ position: "absolute", zIndex: "1", top: '30%', right: "60%", transform: 'rotate(-90deg)'}} />
                        </div>

                        <div>
                            <h3 style={{ fontSize: "22px", fontWeight: "bold" }}>A Data Platform</h3>
                            <p>Opens the door for opportunities</p>
                        </div>
                        <div className="text-center text-white-75">
                            <p style={{ marginBottom: "0px" }}>Crafted with <HeartFilled style={{ color: customStyles.colorDanger }} /> by Engineers in NYC</p>
                        </div>
                    </div>
                </Col>
        </React.Fragment>
    )
}

export default ParticleAuth