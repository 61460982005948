const multicommon = [
    { year: "1850", value: 0, category: "Liquid fuel" },
    { year: "1850", value: 54, category: "Solid fuel" },
    { year: "1850", value: 0, category: "Gas fuel" },
    { year: "1850", value: 0, category: "Cement production" },
    { year: "1850", value: 0, category: "Gas flarinl" },
    { year: "1851", value: 0, category: "Liquid fuel" },
    { year: "1851", value: 54, category: "Solid fuel" },
    { year: "1851", value: 0, category: "Gas fuel" },
    { year: "1851", value: 0, category: "Cement production" },
    { year: "1851", value: 0, category: "Gas flarinl" },
    { year: "1852", value: 0, category: "Liquid fuel" },
    { year: "1852", value: 57, category: "Solid fuel" },
    { year: "1852", value: 0, category: "Gas fuel" },
    { year: "1852", value: 0, category: "Cement production" },
    { year: "1852", value: 0, category: "Gas flarinl" },
    { year: "1853", value: 0, category: "Liquid fuel" },
    { year: "1853", value: 59, category: "Solid fuel" },
    { year: "1853", value: 0, category: "Gas fuel" },
    { year: "1853", value: 0, category: "Cement production" },
    { year: "1853", value: 0, category: "Gas flarinl" },
    { year: "1854", value: 0, category: "Liquid fuel" },
    { year: "1854", value: 69, category: "Solid fuel" },
    { year: "1854", value: 0, category: "Gas fuel" },
    { year: "1854", value: 0, category: "Cement production" },
    { year: "1854", value: 0, category: "Gas flarinl" },
    { year: "1855", value: 0, category: "Liquid fuel" },
    { year: "1855", value: 71, category: "Solid fuel" },
    { year: "1855", value: 0, category: "Gas fuel" },
    { year: "1855", value: 0, category: "Cement production" },
    { year: "1855", value: 0, category: "Gas flarinl" },
    { year: "1856", value: 0, category: "Liquid fuel" },
    { year: "1856", value: 76, category: "Solid fuel" },
    { year: "1856", value: 0, category: "Gas fuel" },
    { year: "1856", value: 0, category: "Cement production" },
    { year: "1856", value: 0, category: "Gas flarinl" },
    { year: "1857", value: 0, category: "Liquid fuel" },
    { year: "1857", value: 77, category: "Solid fuel" },
    { year: "1857", value: 0, category: "Gas fuel" },
    { year: "1857", value: 0, category: "Cement production" },
    { year: "1857", value: 0, category: "Gas flarinl" },
    { year: "1858", value: 0, category: "Liquid fuel" },
    { year: "1858", value: 78, category: "Solid fuel" },
    { year: "1858", value: 0, category: "Gas fuel" },
    { year: "1858", value: 0, category: "Cement production" },
    { year: "1858", value: 0, category: "Gas flarinl" },
    { year: "1859", value: 0, category: "Liquid fuel" },
    { year: "1859", value: 83, category: "Solid fuel" },
    { year: "1859", value: 0, category: "Gas fuel" },
    { year: "1859", value: 0, category: "Cement production" },
    { year: "1859", value: 0, category: "Gas flarinl" },
    { year: "1860", value: 0, category: "Liquid fuel" },
    { year: "1860", value: 91, category: "Solid fuel" },
    { year: "1860", value: 0, category: "Gas fuel" },
    { year: "1860", value: 0, category: "Cement production" },
    { year: "1860", value: 0, category: "Gas flarinl" },
    { year: "1861", value: 0, category: "Liquid fuel" },
    { year: "1861", value: 95, category: "Solid fuel" },
    { year: "1861", value: 0, category: "Gas fuel" },
    { year: "1861", value: 0, category: "Cement production" },
    { year: "1861", value: 0, category: "Gas flarinl" },
    { year: "1862", value: 0, category: "Liquid fuel" },
    { year: "1862", value: 96, category: "Solid fuel" },
    { year: "1862", value: 0, category: "Gas fuel" },
    { year: "1862", value: 0, category: "Cement production" },
    { year: "1862", value: 0, category: "Gas flarinl" },
    { year: "1863", value: 0, category: "Liquid fuel" },
    { year: "1863", value: 103, category: "Solid fuel" },
    { year: "1863", value: 0, category: "Gas fuel" },
    { year: "1863", value: 0, category: "Cement production" },
    { year: "1863", value: 0, category: "Gas flarinl" },
    { year: "1864", value: 0, category: "Liquid fuel" },
    { year: "1864", value: 112, category: "Solid fuel" },
    { year: "1864", value: 0, category: "Gas fuel" },
    { year: "1864", value: 0, category: "Cement production" },
    { year: "1864", value: 0, category: "Gas flarinl" },
    { year: "1865", value: 0, category: "Liquid fuel" },
    { year: "1865", value: 119, category: "Solid fuel" },
    { year: "1865", value: 0, category: "Gas fuel" },
    { year: "1865", value: 0, category: "Cement production" },
    { year: "1865", value: 0, category: "Gas flarinl" },
    { year: "1866", value: 0, category: "Liquid fuel" },
    { year: "1866", value: 122, category: "Solid fuel" },
    { year: "1866", value: 0, category: "Gas fuel" },
    { year: "1866", value: 0, category: "Cement production" },
    { year: "1866", value: 0, category: "Gas flarinl" },
    { year: "1867", value: 0, category: "Liquid fuel" },
    { year: "1867", value: 130, category: "Solid fuel" },
    { year: "1867", value: 0, category: "Gas fuel" },
    { year: "1867", value: 0, category: "Cement production" },
    { year: "1867", value: 0, category: "Gas flarinl" },
    { year: "1868", value: 0, category: "Liquid fuel" },
    { year: "1868", value: 134, category: "Solid fuel" },
    { year: "1868", value: 0, category: "Gas fuel" },
    { year: "1868", value: 0, category: "Cement production" },
    { year: "1868", value: 0, category: "Gas flarinl" },
    { year: "1869", value: 0, category: "Liquid fuel" },
    { year: "1869", value: 142, category: "Solid fuel" },
    { year: "1869", value: 0, category: "Gas fuel" },
    { year: "1869", value: 0, category: "Cement production" },
    { year: "1869", value: 0, category: "Gas flarinl" },
    { year: "1870", value: 1, category: "Liquid fuel" },
    { year: "1870", value: 146, category: "Solid fuel" },
    { year: "1870", value: 0, category: "Gas fuel" },
    { year: "1870", value: 0, category: "Cement production" },
    { year: "1870", value: 0, category: "Gas flarinl" },
    { year: "1871", value: 1, category: "Liquid fuel" },
    { year: "1871", value: 156, category: "Solid fuel" },
    { year: "1871", value: 0, category: "Gas fuel" },
    { year: "1871", value: 0, category: "Cement production" },
    { year: "1871", value: 0, category: "Gas flarinl" },
    { year: "1872", value: 1, category: "Liquid fuel" },
    { year: "1872", value: 173, category: "Solid fuel" },
    { year: "1872", value: 0, category: "Gas fuel" },
    { year: "1872", value: 0, category: "Cement production" },
    { year: "1872", value: 0, category: "Gas flarinl" },
    { year: "1873", value: 1, category: "Liquid fuel" },
    { year: "1873", value: 183, category: "Solid fuel" },
    { year: "1873", value: 0, category: "Gas fuel" },
    { year: "1873", value: 0, category: "Cement production" },
    { year: "1873", value: 0, category: "Gas flarinl" },
    { year: "1874", value: 1, category: "Liquid fuel" },
    { year: "1874", value: 173, category: "Solid fuel" },
    { year: "1874", value: 0, category: "Gas fuel" },
    { year: "1874", value: 0, category: "Cement production" },
    { year: "1874", value: 0, category: "Gas flarinl" },
    { year: "1875", value: 1, category: "Liquid fuel" },
    { year: "1875", value: 187, category: "Solid fuel" },
    { year: "1875", value: 0, category: "Gas fuel" },
    { year: "1875", value: 0, category: "Cement production" },
    { year: "1875", value: 0, category: "Gas flarinl" },
    { year: "1876", value: 1, category: "Liquid fuel" },
    { year: "1876", value: 190, category: "Solid fuel" },
    { year: "1876", value: 0, category: "Gas fuel" },
    { year: "1876", value: 0, category: "Cement production" },
    { year: "1876", value: 0, category: "Gas flarinl" },
    { year: "1877", value: 2, category: "Liquid fuel" },
    { year: "1877", value: 192, category: "Solid fuel" },
    { year: "1877", value: 0, category: "Gas fuel" },
    { year: "1877", value: 0, category: "Cement production" },
    { year: "1877", value: 0, category: "Gas flarinl" },
    { year: "1878", value: 2, category: "Liquid fuel" },
    { year: "1878", value: 194, category: "Solid fuel" },
    { year: "1878", value: 0, category: "Gas fuel" },
    { year: "1878", value: 0, category: "Cement production" },
    { year: "1878", value: 0, category: "Gas flarinl" },
    { year: "1879", value: 3, category: "Liquid fuel" },
    { year: "1879", value: 207, category: "Solid fuel" },
    { year: "1879", value: 0, category: "Gas fuel" },
    { year: "1879", value: 0, category: "Cement production" },
    { year: "1879", value: 0, category: "Gas flarinl" },
    { year: "1880", value: 3, category: "Liquid fuel" },
    { year: "1880", value: 233, category: "Solid fuel" },
    { year: "1880", value: 0, category: "Gas fuel" },
    { year: "1880", value: 0, category: "Cement production" },
    { year: "1880", value: 0, category: "Gas flarinl" },
    { year: "1881", value: 4, category: "Liquid fuel" },
    { year: "1881", value: 239, category: "Solid fuel" },
    { year: "1881", value: 0, category: "Gas fuel" },
    { year: "1881", value: 0, category: "Cement production" },
    { year: "1881", value: 0, category: "Gas flarinl" },
    { year: "1882", value: 4, category: "Liquid fuel" },
    { year: "1882", value: 252, category: "Solid fuel" },
    { year: "1882", value: 0, category: "Gas fuel" },
    { year: "1882", value: 0, category: "Cement production" },
    { year: "1882", value: 0, category: "Gas flarinl" },
    { year: "1883", value: 3, category: "Liquid fuel" },
    { year: "1883", value: 269, category: "Solid fuel" },
    { year: "1883", value: 0, category: "Gas fuel" },
    { year: "1883", value: 0, category: "Cement production" },
    { year: "1883", value: 0, category: "Gas flarinl" },
    { year: "1884", value: 4, category: "Liquid fuel" },
    { year: "1884", value: 271, category: "Solid fuel" },
    { year: "1884", value: 0, category: "Gas fuel" },
    { year: "1884", value: 0, category: "Cement production" },
    { year: "1884", value: 0, category: "Gas flarinl" },
    { year: "1885", value: 4, category: "Liquid fuel" },
    { year: "1885", value: 273, category: "Solid fuel" },
    { year: "1885", value: 1, category: "Gas fuel" },
    { year: "1885", value: 0, category: "Cement production" },
    { year: "1885", value: 0, category: "Gas flarinl" },
    { year: "1886", value: 5, category: "Liquid fuel" },
    { year: "1886", value: 275, category: "Solid fuel" },
    { year: "1886", value: 2, category: "Gas fuel" },
    { year: "1886", value: 0, category: "Cement production" },
    { year: "1886", value: 0, category: "Gas flarinl" },
    { year: "1887", value: 5, category: "Liquid fuel" },
    { year: "1887", value: 287, category: "Solid fuel" },
    { year: "1887", value: 3, category: "Gas fuel" },
    { year: "1887", value: 0, category: "Cement production" },
    { year: "1887", value: 0, category: "Gas flarinl" },
    { year: "1888", value: 5, category: "Liquid fuel" },
    { year: "1888", value: 317, category: "Solid fuel" },
    { year: "1888", value: 5, category: "Gas fuel" },
    { year: "1888", value: 0, category: "Cement production" },
    { year: "1888", value: 0, category: "Gas flarinl" },
    { year: "1889", value: 6, category: "Liquid fuel" },
    { year: "1889", value: 318, category: "Solid fuel" },
    { year: "1889", value: 3, category: "Gas fuel" },
    { year: "1889", value: 0, category: "Cement production" },
    { year: "1889", value: 0, category: "Gas flarinl" },
    { year: "1890", value: 8, category: "Liquid fuel" },
    { year: "1890", value: 345, category: "Solid fuel" },
    { year: "1890", value: 3, category: "Gas fuel" },
    { year: "1890", value: 0, category: "Cement production" },
    { year: "1890", value: 0, category: "Gas flarinl" },
    { year: "1891", value: 9, category: "Liquid fuel" },
    { year: "1891", value: 360, category: "Solid fuel" },
    { year: "1891", value: 2, category: "Gas fuel" },
    { year: "1891", value: 0, category: "Cement production" },
    { year: "1891", value: 0, category: "Gas flarinl" },
    { year: "1892", value: 9, category: "Liquid fuel" },
    { year: "1892", value: 363, category: "Solid fuel" },
    { year: "1892", value: 2, category: "Gas fuel" },
    { year: "1892", value: 0, category: "Cement production" },
    { year: "1892", value: 0, category: "Gas flarinl" },
    { year: "1893", value: 10, category: "Liquid fuel" },
    { year: "1893", value: 358, category: "Solid fuel" },
    { year: "1893", value: 2, category: "Gas fuel" },
    { year: "1893", value: 0, category: "Cement production" },
    { year: "1893", value: 0, category: "Gas flarinl" },
    { year: "1894", value: 9, category: "Liquid fuel" },
    { year: "1894", value: 372, category: "Solid fuel" },
    { year: "1894", value: 2, category: "Gas fuel" },
    { year: "1894", value: 0, category: "Cement production" },
    { year: "1894", value: 0, category: "Gas flarinl" },
    { year: "1895", value: 11, category: "Liquid fuel" },
    { year: "1895", value: 393, category: "Solid fuel" },
    { year: "1895", value: 2, category: "Gas fuel" },
    { year: "1895", value: 0, category: "Cement production" },
    { year: "1895", value: 0, category: "Gas flarinl" },
    { year: "1896", value: 12, category: "Liquid fuel" },
    { year: "1896", value: 405, category: "Solid fuel" },
    { year: "1896", value: 2, category: "Gas fuel" },
    { year: "1896", value: 0, category: "Cement production" },
    { year: "1896", value: 0, category: "Gas flarinl" },
    { year: "1897", value: 13, category: "Liquid fuel" },
    { year: "1897", value: 425, category: "Solid fuel" },
    { year: "1897", value: 2, category: "Gas fuel" },
    { year: "1897", value: 0, category: "Cement production" },
    { year: "1897", value: 0, category: "Gas flarinl" },
    { year: "1898", value: 13, category: "Liquid fuel" },
    { year: "1898", value: 449, category: "Solid fuel" },
    { year: "1898", value: 2, category: "Gas fuel" },
    { year: "1898", value: 0, category: "Cement production" },
    { year: "1898", value: 0, category: "Gas flarinl" },
    { year: "1899", value: 14, category: "Liquid fuel" },
    { year: "1899", value: 491, category: "Solid fuel" },
    { year: "1899", value: 3, category: "Gas fuel" },
    { year: "1899", value: 0, category: "Cement production" },
    { year: "1899", value: 0, category: "Gas flarinl" },
    { year: "1900", value: 16, category: "Liquid fuel" },
    { year: "1900", value: 515, category: "Solid fuel" },
    { year: "1900", value: 3, category: "Gas fuel" },
    { year: "1900", value: 0, category: "Cement production" },
    { year: "1900", value: 0, category: "Gas flarinl" },
    { year: "1901", value: 18, category: "Liquid fuel" },
    { year: "1901", value: 531, category: "Solid fuel" },
    { year: "1901", value: 4, category: "Gas fuel" },
    { year: "1901", value: 0, category: "Cement production" },
    { year: "1901", value: 0, category: "Gas flarinl" },
    { year: "1902", value: 19, category: "Liquid fuel" },
    { year: "1902", value: 543, category: "Solid fuel" },
    { year: "1902", value: 4, category: "Gas fuel" },
    { year: "1902", value: 0, category: "Cement production" },
    { year: "1902", value: 0, category: "Gas flarinl" },
    { year: "1903", value: 20, category: "Liquid fuel" },
    { year: "1903", value: 593, category: "Solid fuel" },
    { year: "1903", value: 4, category: "Gas fuel" },
    { year: "1903", value: 0, category: "Cement production" },
    { year: "1903", value: 0, category: "Gas flarinl" },
    { year: "1904", value: 23, category: "Liquid fuel" },
    { year: "1904", value: 597, category: "Solid fuel" },
    { year: "1904", value: 4, category: "Gas fuel" },
    { year: "1904", value: 0, category: "Cement production" },
    { year: "1904", value: 0, category: "Gas flarinl" },
    { year: "1905", value: 23, category: "Liquid fuel" },
    { year: "1905", value: 636, category: "Solid fuel" },
    { year: "1905", value: 5, category: "Gas fuel" },
    { year: "1905", value: 0, category: "Cement production" },
    { year: "1905", value: 0, category: "Gas flarinl" },
    { year: "1906", value: 23, category: "Liquid fuel" },
    { year: "1906", value: 680, category: "Solid fuel" },
    { year: "1906", value: 5, category: "Gas fuel" },
    { year: "1906", value: 0, category: "Cement production" },
    { year: "1906", value: 0, category: "Gas flarinl" },
    { year: "1907", value: 28, category: "Liquid fuel" },
    { year: "1907", value: 750, category: "Solid fuel" },
    { year: "1907", value: 5, category: "Gas fuel" },
    { year: "1907", value: 0, category: "Cement production" },
    { year: "1907", value: 0, category: "Gas flarinl" },
    { year: "1908", value: 30, category: "Liquid fuel" },
    { year: "1908", value: 714, category: "Solid fuel" },
    { year: "1908", value: 5, category: "Gas fuel" },
    { year: "1908", value: 0, category: "Cement production" },
    { year: "1908", value: 0, category: "Gas flarinl" },
    { year: "1909", value: 32, category: "Liquid fuel" },
    { year: "1909", value: 747, category: "Solid fuel" },
    { year: "1909", value: 6, category: "Gas fuel" },
    { year: "1909", value: 0, category: "Cement production" },
    { year: "1909", value: 0, category: "Gas flarinl" },
    { year: "1910", value: 34, category: "Liquid fuel" },
    { year: "1910", value: 778, category: "Solid fuel" },
    { year: "1910", value: 7, category: "Gas fuel" },
    { year: "1910", value: 0, category: "Cement production" },
    { year: "1910", value: 0, category: "Gas flarinl" },
    { year: "1911", value: 36, category: "Liquid fuel" },
    { year: "1911", value: 792, category: "Solid fuel" },
    { year: "1911", value: 7, category: "Gas fuel" },
    { year: "1911", value: 0, category: "Cement production" },
    { year: "1911", value: 0, category: "Gas flarinl" },
    { year: "1912", value: 37, category: "Liquid fuel" },
    { year: "1912", value: 834, category: "Solid fuel" },
    { year: "1912", value: 8, category: "Gas fuel" },
    { year: "1912", value: 0, category: "Cement production" },
    { year: "1912", value: 0, category: "Gas flarinl" },
    { year: "1913", value: 41, category: "Liquid fuel" },
    { year: "1913", value: 895, category: "Solid fuel" },
    { year: "1913", value: 8, category: "Gas fuel" },
    { year: "1913", value: 0, category: "Cement production" },
    { year: "1913", value: 0, category: "Gas flarinl" },
    { year: "1914", value: 42, category: "Liquid fuel" },
    { year: "1914", value: 800, category: "Solid fuel" },
    { year: "1914", value: 8, category: "Gas fuel" },
    { year: "1914", value: 0, category: "Cement production" },
    { year: "1914", value: 0, category: "Gas flarinl" },
    { year: "1915", value: 45, category: "Liquid fuel" },
    { year: "1915", value: 784, category: "Solid fuel" },
    { year: "1915", value: 9, category: "Gas fuel" },
    { year: "1915", value: 0, category: "Cement production" },
    { year: "1915", value: 0, category: "Gas flarinl" },
    { year: "1916", value: 48, category: "Liquid fuel" },
    { year: "1916", value: 842, category: "Solid fuel" },
    { year: "1916", value: 10, category: "Gas fuel" },
    { year: "1916", value: 0, category: "Cement production" },
    { year: "1916", value: 0, category: "Gas flarinl" },
    { year: "1917", value: 54, category: "Liquid fuel" },
    { year: "1917", value: 891, category: "Solid fuel" },
    { year: "1917", value: 11, category: "Gas fuel" },
    { year: "1917", value: 0, category: "Cement production" },
    { year: "1917", value: 0, category: "Gas flarinl" },
    { year: "1918", value: 53, category: "Liquid fuel" },
    { year: "1918", value: 873, category: "Solid fuel" },
    { year: "1918", value: 10, category: "Gas fuel" },
    { year: "1918", value: 0, category: "Cement production" },
    { year: "1918", value: 0, category: "Gas flarinl" },
    { year: "1919", value: 61, category: "Liquid fuel" },
    { year: "1919", value: 735, category: "Solid fuel" },
    { year: "1919", value: 10, category: "Gas fuel" },
    { year: "1919", value: 0, category: "Cement production" },
    { year: "1919", value: 0, category: "Gas flarinl" },
    { year: "1920", value: 78, category: "Liquid fuel" },
    { year: "1920", value: 843, category: "Solid fuel" },
    { year: "1920", value: 11, category: "Gas fuel" },
    { year: "1920", value: 0, category: "Cement production" },
    { year: "1920", value: 0, category: "Gas flarinl" },
    { year: "1921", value: 84, category: "Liquid fuel" },
    { year: "1921", value: 709, category: "Solid fuel" },
    { year: "1921", value: 10, category: "Gas fuel" },
    { year: "1921", value: 0, category: "Cement production" },
    { year: "1921", value: 0, category: "Gas flarinl" },
    { year: "1922", value: 94, category: "Liquid fuel" },
    { year: "1922", value: 740, category: "Solid fuel" },
    { year: "1922", value: 11, category: "Gas fuel" },
    { year: "1922", value: 0, category: "Cement production" },
    { year: "1922", value: 0, category: "Gas flarinl" },
    { year: "1923", value: 111, category: "Liquid fuel" },
    { year: "1923", value: 845, category: "Solid fuel" },
    { year: "1923", value: 14, category: "Gas fuel" },
    { year: "1923", value: 0, category: "Cement production" },
    { year: "1923", value: 0, category: "Gas flarinl" },
    { year: "1924", value: 110, category: "Liquid fuel" },
    { year: "1924", value: 836, category: "Solid fuel" },
    { year: "1924", value: 16, category: "Gas fuel" },
    { year: "1924", value: 0, category: "Cement production" },
    { year: "1924", value: 0, category: "Gas flarinl" },
    { year: "1925", value: 116, category: "Liquid fuel" },
    { year: "1925", value: 842, category: "Solid fuel" },
    { year: "1925", value: 17, category: "Gas fuel" },
    { year: "1925", value: 0, category: "Cement production" },
    { year: "1925", value: 0, category: "Gas flarinl" },
    { year: "1926", value: 119, category: "Liquid fuel" },
    { year: "1926", value: 846, category: "Solid fuel" },
    { year: "1926", value: 19, category: "Gas fuel" },
    { year: "1926", value: 0, category: "Cement production" },
    { year: "1926", value: 0, category: "Gas flarinl" },
    { year: "1927", value: 136, category: "Liquid fuel" },
    { year: "1927", value: 905, category: "Solid fuel" },
    { year: "1927", value: 21, category: "Gas fuel" },
    { year: "1927", value: 0, category: "Cement production" },
    { year: "1927", value: 0, category: "Gas flarinl" },
    { year: "1928", value: 143, category: "Liquid fuel" },
    { year: "1928", value: 890, category: "Solid fuel" },
    { year: "1928", value: 23, category: "Gas fuel" },
    { year: "1928", value: 10, category: "Cement production" },
    { year: "1928", value: 0, category: "Gas flarinl" },
    { year: "1929", value: 160, category: "Liquid fuel" },
    { year: "1929", value: 947, category: "Solid fuel" },
    { year: "1929", value: 28, category: "Gas fuel" },
    { year: "1929", value: 10, category: "Cement production" },
    { year: "1929", value: 0, category: "Gas flarinl" },
    { year: "1930", value: 152, category: "Liquid fuel" },
    { year: "1930", value: 862, category: "Solid fuel" },
    { year: "1930", value: 28, category: "Gas fuel" },
    { year: "1930", value: 10, category: "Cement production" },
    { year: "1930", value: 0, category: "Gas flarinl" },
    { year: "1931", value: 147, category: "Liquid fuel" },
    { year: "1931", value: 759, category: "Solid fuel" },
    { year: "1931", value: 25, category: "Gas fuel" },
    { year: "1931", value: 8, category: "Cement production" },
    { year: "1931", value: 0, category: "Gas flarinl" },
    { year: "1932", value: 141, category: "Liquid fuel" },
    { year: "1932", value: 675, category: "Solid fuel" },
    { year: "1932", value: 24, category: "Gas fuel" },
    { year: "1932", value: 7, category: "Cement production" },
    { year: "1932", value: 0, category: "Gas flarinl" },
    { year: "1933", value: 154, category: "Liquid fuel" },
    { year: "1933", value: 708, category: "Solid fuel" },
    { year: "1933", value: 25, category: "Gas fuel" },
    { year: "1933", value: 7, category: "Cement production" },
    { year: "1933", value: 0, category: "Gas flarinl" },
    { year: "1934", value: 162, category: "Liquid fuel" },
    { year: "1934", value: 775, category: "Solid fuel" },
    { year: "1934", value: 28, category: "Gas fuel" },
    { year: "1934", value: 8, category: "Cement production" },
    { year: "1934", value: 0, category: "Gas flarinl" },
    { year: "1935", value: 176, category: "Liquid fuel" },
    { year: "1935", value: 811, category: "Solid fuel" },
    { year: "1935", value: 30, category: "Gas fuel" },
    { year: "1935", value: 9, category: "Cement production" },
    { year: "1935", value: 0, category: "Gas flarinl" },
    { year: "1936", value: 192, category: "Liquid fuel" },
    { year: "1936", value: 893, category: "Solid fuel" },
    { year: "1936", value: 34, category: "Gas fuel" },
    { year: "1936", value: 11, category: "Cement production" },
    { year: "1936", value: 0, category: "Gas flarinl" },
    { year: "1937", value: 219, category: "Liquid fuel" },
    { year: "1937", value: 941, category: "Solid fuel" },
    { year: "1937", value: 38, category: "Gas fuel" },
    { year: "1937", value: 11, category: "Cement production" },
    { year: "1937", value: 0, category: "Gas flarinl" },
    { year: "1938", value: 214, category: "Liquid fuel" },
    { year: "1938", value: 880, category: "Solid fuel" },
    { year: "1938", value: 37, category: "Gas fuel" },
    { year: "1938", value: 12, category: "Cement production" },
    { year: "1938", value: 0, category: "Gas flarinl" },
    { year: "1939", value: 222, category: "Liquid fuel" },
    { year: "1939", value: 918, category: "Solid fuel" },
    { year: "1939", value: 38, category: "Gas fuel" },
    { year: "1939", value: 13, category: "Cement production" },
    { year: "1939", value: 0, category: "Gas flarinl" },
    { year: "1940", value: 229, category: "Liquid fuel" },
    { year: "1940", value: 1017, category: "Solid fuel" },
    { year: "1940", value: 42, category: "Gas fuel" },
    { year: "1940", value: 11, category: "Cement production" },
    { year: "1940", value: 0, category: "Gas flarinl" },
    { year: "1941", value: 236, category: "Liquid fuel" },
    { year: "1941", value: 1043, category: "Solid fuel" },
    { year: "1941", value: 42, category: "Gas fuel" },
    { year: "1941", value: 12, category: "Cement production" },
    { year: "1941", value: 0, category: "Gas flarinl" },
    { year: "1942", value: 222, category: "Liquid fuel" },
    { year: "1942", value: 1063, category: "Solid fuel" },
    { year: "1942", value: 45, category: "Gas fuel" },
    { year: "1942", value: 11, category: "Cement production" },
    { year: "1942", value: 0, category: "Gas flarinl" },
    { year: "1943", value: 239, category: "Liquid fuel" },
    { year: "1943", value: 1092, category: "Solid fuel" },
    { year: "1943", value: 50, category: "Gas fuel" },
    { year: "1943", value: 10, category: "Cement production" },
    { year: "1943", value: 0, category: "Gas flarinl" },
    { year: "1944", value: 275, category: "Liquid fuel" },
    { year: "1944", value: 1047, category: "Solid fuel" },
    { year: "1944", value: 54, category: "Gas fuel" },
    { year: "1944", value: 7, category: "Cement production" },
    { year: "1944", value: 0, category: "Gas flarinl" },
    { year: "1945", value: 275, category: "Liquid fuel" },
    { year: "1945", value: 820, category: "Solid fuel" },
    { year: "1945", value: 59, category: "Gas fuel" },
    { year: "1945", value: 7, category: "Cement production" },
    { year: "1945", value: 0, category: "Gas flarinl" },
    { year: "1946", value: 292, category: "Liquid fuel" },
    { year: "1946", value: 875, category: "Solid fuel" },
    { year: "1946", value: 61, category: "Gas fuel" },
    { year: "1946", value: 10, category: "Cement production" },
    { year: "1946", value: 0, category: "Gas flarinl" },
    { year: "1947", value: 322, category: "Liquid fuel" },
    { year: "1947", value: 992, category: "Solid fuel" },
    { year: "1947", value: 67, category: "Gas fuel" },
    { year: "1947", value: 12, category: "Cement production" },
    { year: "1947", value: 0, category: "Gas flarinl" },
    { year: "1948", value: 364, category: "Liquid fuel" },
    { year: "1948", value: 1015, category: "Solid fuel" },
    { year: "1948", value: 76, category: "Gas fuel" },
    { year: "1948", value: 14, category: "Cement production" },
    { year: "1948", value: 0, category: "Gas flarinl" },
    { year: "1949", value: 362, category: "Liquid fuel" },
    { year: "1949", value: 960, category: "Solid fuel" },
    { year: "1949", value: 81, category: "Gas fuel" },
    { year: "1949", value: 16, category: "Cement production" },
    { year: "1949", value: 0, category: "Gas flarinl" },
    { year: "1950", value: 423, category: "Liquid fuel" },
    { year: "1950", value: 1070, category: "Solid fuel" },
    { year: "1950", value: 97, category: "Gas fuel" },
    { year: "1950", value: 18, category: "Cement production" },
    { year: "1950", value: 23, category: "Gas flarinl" },
    { year: "1951", value: 479, category: "Liquid fuel" },
    { year: "1951", value: 1129, category: "Solid fuel" },
    { year: "1951", value: 115, category: "Gas fuel" },
    { year: "1951", value: 20, category: "Cement production" },
    { year: "1951", value: 24, category: "Gas flarinl" },
    { year: "1952", value: 504, category: "Liquid fuel" },
    { year: "1952", value: 1119, category: "Solid fuel" },
    { year: "1952", value: 124, category: "Gas fuel" },
    { year: "1952", value: 22, category: "Cement production" },
    { year: "1952", value: 26, category: "Gas flarinl" },
    { year: "1953", value: 533, category: "Liquid fuel" },
    { year: "1953", value: 1125, category: "Solid fuel" },
    { year: "1953", value: 131, category: "Gas fuel" },
    { year: "1953", value: 24, category: "Cement production" },
    { year: "1953", value: 27, category: "Gas flarinl" },
    { year: "1954", value: 557, category: "Liquid fuel" },
    { year: "1954", value: 1116, category: "Solid fuel" },
    { year: "1954", value: 138, category: "Gas fuel" },
    { year: "1954", value: 27, category: "Cement production" },
    { year: "1954", value: 27, category: "Gas flarinl" },
    { year: "1955", value: 625, category: "Liquid fuel" },
    { year: "1955", value: 1208, category: "Solid fuel" },
    { year: "1955", value: 150, category: "Gas fuel" },
    { year: "1955", value: 30, category: "Cement production" },
    { year: "1955", value: 31, category: "Gas flarinl" },
    { year: "1956", value: 679, category: "Liquid fuel" },
    { year: "1956", value: 1273, category: "Solid fuel" },
    { year: "1956", value: 161, category: "Gas fuel" },
    { year: "1956", value: 32, category: "Cement production" },
    { year: "1956", value: 32, category: "Gas flarinl" },
    { year: "1957", value: 714, category: "Liquid fuel" },
    { year: "1957", value: 1309, category: "Solid fuel" },
    { year: "1957", value: 178, category: "Gas fuel" },
    { year: "1957", value: 34, category: "Cement production" },
    { year: "1957", value: 35, category: "Gas flarinl" },
    { year: "1958", value: 731, category: "Liquid fuel" },
    { year: "1958", value: 1336, category: "Solid fuel" },
    { year: "1958", value: 192, category: "Gas fuel" },
    { year: "1958", value: 36, category: "Cement production" },
    { year: "1958", value: 35, category: "Gas flarinl" },
    { year: "1959", value: 789, category: "Liquid fuel" },
    { year: "1959", value: 1382, category: "Solid fuel" },
    { year: "1959", value: 206, category: "Gas fuel" },
    { year: "1959", value: 40, category: "Cement production" },
    { year: "1959", value: 36, category: "Gas flarinl" },
    { year: "1960", value: 849, category: "Liquid fuel" },
    { year: "1960", value: 1410, category: "Solid fuel" },
    { year: "1960", value: 227, category: "Gas fuel" },
    { year: "1960", value: 43, category: "Cement production" },
    { year: "1960", value: 39, category: "Gas flarinl" },
    { year: "1961", value: 904, category: "Liquid fuel" },
    { year: "1961", value: 1349, category: "Solid fuel" },
    { year: "1961", value: 240, category: "Gas fuel" },
    { year: "1961", value: 45, category: "Cement production" },
    { year: "1961", value: 42, category: "Gas flarinl" },
    { year: "1962", value: 980, category: "Liquid fuel" },
    { year: "1962", value: 1351, category: "Solid fuel" },
    { year: "1962", value: 263, category: "Gas fuel" },
    { year: "1962", value: 49, category: "Cement production" },
    { year: "1962", value: 44, category: "Gas flarinl" },
    { year: "1963", value: 1052, category: "Liquid fuel" },
    { year: "1963", value: 1396, category: "Solid fuel" },
    { year: "1963", value: 286, category: "Gas fuel" },
    { year: "1963", value: 51, category: "Cement production" },
    { year: "1963", value: 47, category: "Gas flarinl" },
    { year: "1964", value: 1137, category: "Liquid fuel" },
    { year: "1964", value: 1435, category: "Solid fuel" },
    { year: "1964", value: 316, category: "Gas fuel" },
    { year: "1964", value: 57, category: "Cement production" },
    { year: "1964", value: 51, category: "Gas flarinl" },
    { year: "1965", value: 1219, category: "Liquid fuel" },
    { year: "1965", value: 1460, category: "Solid fuel" },
    { year: "1965", value: 337, category: "Gas fuel" },
    { year: "1965", value: 59, category: "Cement production" },
    { year: "1965", value: 55, category: "Gas flarinl" },
    { year: "1966", value: 1323, category: "Liquid fuel" },
    { year: "1966", value: 1478, category: "Solid fuel" },
    { year: "1966", value: 364, category: "Gas fuel" },
    { year: "1966", value: 63, category: "Cement production" },
    { year: "1966", value: 60, category: "Gas flarinl" },
    { year: "1967", value: 1423, category: "Liquid fuel" },
    { year: "1967", value: 1448, category: "Solid fuel" },
    { year: "1967", value: 392, category: "Gas fuel" },
    { year: "1967", value: 65, category: "Cement production" },
    { year: "1967", value: 66, category: "Gas flarinl" },
    { year: "1968", value: 1551, category: "Liquid fuel" },
    { year: "1968", value: 1448, category: "Solid fuel" },
    { year: "1968", value: 424, category: "Gas fuel" },
    { year: "1968", value: 70, category: "Cement production" },
    { year: "1968", value: 73, category: "Gas flarinl" },
    { year: "1969", value: 1673, category: "Liquid fuel" },
    { year: "1969", value: 1486, category: "Solid fuel" },
    { year: "1969", value: 467, category: "Gas fuel" },
    { year: "1969", value: 74, category: "Cement production" },
    { year: "1969", value: 80, category: "Gas flarinl" },
    { year: "1970", value: 1839, category: "Liquid fuel" },
    { year: "1970", value: 1556, category: "Solid fuel" },
    { year: "1970", value: 493, category: "Gas fuel" },
    { year: "1970", value: 78, category: "Cement production" },
    { year: "1970", value: 87, category: "Gas flarinl" },
    { year: "1971", value: 1947, category: "Liquid fuel" },
    { year: "1971", value: 1559, category: "Solid fuel" },
    { year: "1971", value: 530, category: "Gas fuel" },
    { year: "1971", value: 84, category: "Cement production" },
    { year: "1971", value: 88, category: "Gas flarinl" },
    { year: "1972", value: 2057, category: "Liquid fuel" },
    { year: "1972", value: 1576, category: "Solid fuel" },
    { year: "1972", value: 560, category: "Gas fuel" },
    { year: "1972", value: 89, category: "Cement production" },
    { year: "1972", value: 95, category: "Gas flarinl" },
    { year: "1973", value: 2241, category: "Liquid fuel" },
    { year: "1973", value: 1581, category: "Solid fuel" },
    { year: "1973", value: 588, category: "Gas fuel" },
    { year: "1973", value: 95, category: "Cement production" },
    { year: "1973", value: 110, category: "Gas flarinl" },
    { year: "1974", value: 2245, category: "Liquid fuel" },
    { year: "1974", value: 1579, category: "Solid fuel" },
    { year: "1974", value: 597, category: "Gas fuel" },
    { year: "1974", value: 96, category: "Cement production" },
    { year: "1974", value: 107, category: "Gas flarinl" },
    { year: "1975", value: 2132, category: "Liquid fuel" },
    { year: "1975", value: 1673, category: "Solid fuel" },
    { year: "1975", value: 604, category: "Gas fuel" },
    { year: "1975", value: 95, category: "Cement production" },
    { year: "1975", value: 92, category: "Gas flarinl" },
];

const animationdata = [
    { "name": "China", year: "2000", "gdp": 1211346869605.24 },
    { "name": "China", year: "2001", "gdp": 1339395718865.3 },
    { "name": "China", year: "2002", "gdp": 1470550015081.55 },
    { "name": "China", year: "2003", "gdp": 1660287965662.68 },
    { "name": "China", year: "2004", "gdp": 1955347004963.27 },
    { "name": "China", year: "2005", "gdp": 2285965892360.54 },
    { "name": "China", year: "2006", "gdp": 2752131773355.16 },
    { "name": "China", year: "2007", "gdp": 3550342425238.25 },
    { "name": "China", year: "2008", "gdp": 4594306848763.08 },
    { "name": "China", year: "2009", "gdp": 5101702432883.45 },
    { "name": "China", year: "2010", "gdp": 6087164527421.24 },
    { "name": "China", year: "2011", "gdp": 7551500425597.77 },
    { "name": "China", year: "2012", "gdp": 8532230724141.76 },
    { "name": "China", year: "2013", "gdp": 9570405758739.79 },
    { "name": "China", year: "2014", "gdp": 10438529153237.6 },
    { "name": "China", year: "2015", "gdp": 11015542352468.9 },
    { "name": "China", year: "2016", "gdp": 11137945669350.6 },
    { "name": "China", year: "2017", "gdp": 12143491448186.1 },
    { "name": "China", year: "2018", "gdp": 13608151864637.9 },
    { "name": "United States", year: "2000", "gdp": 10252345464000 },
    { "name": "United States", year: "2001", "gdp": 10581821399000 },
    { "name": "United States", year: "2002", "gdp": 10936419054000 },
    { "name": "United States", year: "2003", "gdp": 11458243878000 },
    { "name": "United States", year: "2004", "gdp": 12213729147000 },
    { "name": "United States", year: "2005", "gdp": 13036640229000 },
    { "name": "United States", year: "2006", "gdp": 13814611414000 },
    { "name": "United States", year: "2007", "gdp": 14451858650000 },
    { "name": "United States", year: "2008", "gdp": 14712844084000 },
    { "name": "United States", year: "2009", "gdp": 14448933025000 },
    { "name": "United States", year: "2010", "gdp": 14992052727000 },
    { "name": "United States", year: "2011", "gdp": 15542581104000 },
    { "name": "United States", year: "2012", "gdp": 16197007349000 },
    { "name": "United States", year: "2013", "gdp": 16784849190000 },
    { "name": "United States", year: "2014", "gdp": 17521746534000 },
    { "name": "United States", year: "2015", "gdp": 18219297584000 },
    { "name": "United States", year: "2016", "gdp": 18707188235000 },
    { "name": "United States", year: "2017", "gdp": 19485393853000 },
    { "name": "United States", year: "2018", "gdp": 20544343456936.5 },
    { "name": "United Kingdom", year: "2000", "gdp": 1657816613708.58 },
    { "name": "United Kingdom", year: "2001", "gdp": 1640246149417.01 },
    { "name": "United Kingdom", year: "2002", "gdp": 1784473920863.31 },
    { "name": "United Kingdom", year: "2003", "gdp": 2053018775510.2 },
    { "name": "United Kingdom", year: "2004", "gdp": 2416931526913.22 },
    { "name": "United Kingdom", year: "2005", "gdp": 2538680000000 },
    { "name": "United Kingdom", year: "2006", "gdp": 2713749770009.2 },
    { "name": "United Kingdom", year: "2007", "gdp": 3100882352941.18 },
    { "name": "United Kingdom", year: "2008", "gdp": 2922667279411.76 },
    { "name": "United Kingdom", year: "2009", "gdp": 2410909799034.12 },
    { "name": "United Kingdom", year: "2010", "gdp": 2475244321361.11 },
    { "name": "United Kingdom", year: "2011", "gdp": 2659310054646.23 },
    { "name": "United Kingdom", year: "2012", "gdp": 2704887678386.72 },
    { "name": "United Kingdom", year: "2013", "gdp": 2786022872706.81 },
    { "name": "United Kingdom", year: "2014", "gdp": 3063803240208.01 },
    { "name": "United Kingdom", year: "2015", "gdp": 2928591002002.51 },
    { "name": "United Kingdom", year: "2016", "gdp": 2694283209613.29 },
    { "name": "United Kingdom", year: "2017", "gdp": 2666229179958.01 },
    { "name": "United Kingdom", year: "2018", "gdp": 2855296731521.96 },
    { "name": "Russian", year: "2000", "gdp": 259710142196.94 },
    { "name": "Russian", year: "2001", "gdp": 306602070620.5 },
    { "name": "Russian", year: "2002", "gdp": 345470494417.86 },
    { "name": "Russian", year: "2003", "gdp": 430347770731.79 },
    { "name": "Russian", year: "2004", "gdp": 591016690742.8 },
    { "name": "Russian", year: "2005", "gdp": 764017107992.39 },
    { "name": "Russian", year: "2006", "gdp": 989930542278.7 },
    { "name": "Russian", year: "2007", "gdp": 1299705764823.62 },
    { "name": "Russian", year: "2008", "gdp": 1660846387624.78 },
    { "name": "Russian", year: "2009", "gdp": 1222644282201.86 },
    { "name": "Russian", year: "2010", "gdp": 1524917468442.01 },
    { "name": "Russian", year: "2011", "gdp": 2051661732059.78 },
    { "name": "Russian", year: "2012", "gdp": 2210256976945.38 },
    { "name": "Russian", year: "2013", "gdp": 2297128039058.21 },
    { "name": "Russian", year: "2014", "gdp": 2059984158438.46 },
    { "name": "Russian", year: "2015", "gdp": 1363594369577.82 },
    { "name": "Russian", year: "2016", "gdp": 1282723881134.01 },
    { "name": "Russian", year: "2017", "gdp": 1578624060588.26 },
    { "name": "Russian", year: "2018", "gdp": 1657554647149.87 },
    { "name": "Japan", year: "2000", "gdp": 4887519660744.86 },
    { "name": "Japan", year: "2001", "gdp": 4303544259842.72 },
    { "name": "Japan", year: "2002", "gdp": 4115116279069.77 },
    { "name": "Japan", year: "2003", "gdp": 4445658071221.86 },
    { "name": "Japan", year: "2004", "gdp": 4815148854362.11 },
    { "name": "Japan", year: "2005", "gdp": 4755410630912.14 },
    { "name": "Japan", year: "2006", "gdp": 4530377224970.4 },
    { "name": "Japan", year: "2007", "gdp": 4515264514430.57 },
    { "name": "Japan", year: "2008", "gdp": 5037908465114.48 },
    { "name": "Japan", year: "2009", "gdp": 5231382674593.7 },
    { "name": "Japan", year: "2010", "gdp": 5700098114744.41 },
    { "name": "Japan", year: "2011", "gdp": 6157459594823.72 },
    { "name": "Japan", year: "2012", "gdp": 6203213121334.12 },
    { "name": "Japan", year: "2013", "gdp": 5155717056270.83 },
    { "name": "Japan", year: "2014", "gdp": 4850413536037.84 },
    { "name": "Japan", year: "2015", "gdp": 4389475622588.97 },
    { "name": "Japan", year: "2016", "gdp": 4926667087367.51 },
    { "name": "Japan", year: "2017", "gdp": 4859950558538.97 },
    { "name": "Japan", year: "2018", "gdp": 4971323079771.87 }
]

export { multicommon, animationdata }