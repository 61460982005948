import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    "datacenter_access_token",
    "datacenter_refresh_token",
    "authUser",
  ]);
  if (!cookies?.datacenter_access_token) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <>{props.children}</>;
};

export default AuthProtected;
