import axios from "axios";


const setAuthorization = (instance, token) => {
  // instance?.defaults?.headers?.common["Authorization"] = "Bearer " + token;
};

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const getCookie = (name) => {
  let cookieValue = 0;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};



const http = (baseURL, token_cookie_name) => {
  // const baseURL = process.env.REACT_APP_BASE_URL 
  //     ? process.env.REACT_APP_BASE_URL 
  //     : `http://localhost:8080`;


  let instance = axios.create({
      baseURL: baseURL
  });

  instance.defaults.xsrfCookieName="csrftoken";
  instance.defaults.xsrfHeaderName="X-CSRFToken";
  instance.defaults.withCredentials=true;

  instance.interceptors.request.use((config)=>{
      config["validateStatus"] = (status) => true;
      const accessToken = getCookie(token_cookie_name);
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        config.headers["Access-Control-Allow-Origin"] =  "*";

      return config;
  });

  instance.interceptors.response.use(
      function (response) {
        return response.data ? response : response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
          case 500:
            message = "Internal Server Error";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          case 404:
            message = "Sorry! the data you are looking for could not be found";
            break;
          default:
            message = error.message || error;
        }
        return Promise.reject(message);
      }
    );
  return instance;
};
// const http = instance;
export {http, setAuthorization, getLoggedinUser};