import { Button, Card, Col, Row, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../../slices/thunk";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EyeFilled,
  FolderOutlined,
  HistoryOutlined,
  PlusOutlined,
  StarOutlined,
  TagFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import usecustomStyles from "../../../../Common/customStyles";
import Spinners from "../../../../Common/Spinner";
import { DeleteModal } from "../../../../Common/DeleteModal";
import DrawerFile from "./DrawerFile";
import ContactModal from "./ContactModal";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

const customStyles = usecustomStyles();
const TextMuted = styled.div`
  color: ${({ theme }) => theme.token.textLight};
`;
const TextLight = styled.div`
  color: ${({ theme }) => theme.token.textLight};
`;
const SpeakEasyProfileTable = ({
  contacts,
  loading,
  contactList,
  setContactList,
}) => {
  const dispatch = useDispatch();

  // dispatch data

  useEffect(() => {
    dispatch(onGetContacts());
  }, [dispatch]);

  const [isLoading, setLoading] = useState(loading);

  const [activeList, setActiveList] = useState("all");

  const [isEdit, setIsEdit] = useState(false);

  const [info, setInfo] = useState({
    img: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    label: "",
    tab: "",
  });
  const [isOffcanvas, setIsOffcanvas] = useState(false);

  const [contact, setContact] = useState([]);

  const [fieldValue, setFieldValue] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDelete = () => {
    setDeleteModal(false);
    setContact(null);
  };

  // Delete Contact
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact.id));
      setDeleteModal(false);
      setContact(null);
    }
  };

  // Add && Update

  const [modalShow, setModalShow] = useState(false);

  const toggle = useCallback(() => {
    if (modalShow) {
      setModalShow(false);
      setIsEdit(false);
      setContact(null);
      setFieldValue(null);
    } else {
      setModalShow(true);
    }
  }, [modalShow]);

  // Update Contact
  const handleUpdateContact = useCallback(
    (ele) => {
      const contact = ele;
      setContact({
        id: contact.id,
        name: contact.name,
        img: contact.img,
        email: contact.email,
        phone: contact.phone,
        country: contact.country,
        label: contact.label,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Filter
  const labelFilter = (ele) => {
    let filterData;
    if (ele === "all") {
      filterData = contacts.filter((item) => item);
    } else {
      filterData = contacts.filter(
        (item) => item.tab === ele || item.label === ele
      );
    }
    setActiveList(ele);
    setContactList(filterData);
  };

  const columns = [
    {
      title: "Name",
      // dataIndex: 'img',
      render: ({ img, name }) => {
        return (
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={img}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>{name}</h6>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Label",
      dataIndex: "label",
      render: (text) => (
        <Tag
          color={
            text === "Business"
              ? "cyan"
              : text === "Friends"
              ? "purple"
              : text === "Family"
              ? "success"
              : "red"
          }
          style={{ border: "none" }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (text) => (
        <span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Link
                href="#viewContactoffcanvas"
                style={{ color: customStyles.textMuted, marginRight: "10px" }}
                onClick={() => {
                  setInfo(text);
                  setIsOffcanvas(true);
                }}
                className="viewlist-btn"
              >
                <EyeFilled />
              </Link>
            </div>
            <div>
              <Link
                href="#addContactModal"
                style={{ color: customStyles.textMuted, marginRight: "10px" }}
                onClick={() => handleUpdateContact(text)}
              >
                <EditFilled />
              </Link>
            </div>
            <div>
              <Link
                href="#removeContactModal"
                style={{ color: customStyles.textMuted }}
                onClick={() => {
                  onClickDelete(text);
                }}
              >
                <DeleteFilled />
              </Link>
            </div>
          </div>
        </span>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Row gutter={[16]} style={{ marginTop: "-20px" }}>
        <Col xs={24} lg={24}>
          <div>
            <div id="recomended-jobs" className="table-card">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <Table
                    columns={columns || []}
                    dataSource={(contactList || []).map((conlist, index) => ({
                      ...conlist,
                      key: index,
                    }))}
                    bordered
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/* Delete Modal */}
      <DeleteModal
        show={deleteModal}
        handleClose={toggleDelete}
        deleteModalFunction={handleDeleteContact}
      />
      <DrawerFile
        info={info}
        isOffcanvas={isOffcanvas}
        setIsOffcanvas={setIsOffcanvas}
      />
      <ContactModal
        modalShow={modalShow}
        toggle={toggle}
        contact={contact}
        onUpdateContact={onUpdateContact}
        onAddNewContact={onAddNewContact}
        fieldValue={fieldValue}
        isEdit={isEdit}
        dispatch={dispatch}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default SpeakEasyProfileTable;
