import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import withRouter from "../../Common/withRouter";

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const Logout = () => {
  document.title = "Sign Out" + process.env.REACT_APP_PAGE_TITLE;

  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  deleteCookie("datacenter_access_token");
  deleteCookie("datacenter_refresh_token");

  setTimeout(() => {
    navigate("/login");
  }, 100);

  return <React.Fragment></React.Fragment>;
};

export default withRouter(Logout);
