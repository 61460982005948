import { Button, Card, Dropdown, Input, Modal, Space, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { MoreHorizontal } from 'lucide-react';
import usecustomStyles from '../../../Common/customStyles';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { getAPIKey as onGetAPIKey, addNewAPIKey as onAddNewAPIKey, updateAPIKey as onUpdateAPIKey, deleteAPIKey as onDeleteAPIKey } from "../../../slices/thunk"
import Spinners from '../../../Common/Spinner';
import { DeleteModal } from '../../../Common/DeleteModal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const customStyles = usecustomStyles();

const ApiTable = (data) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'API Key',
            dataIndex: 'apiKey',
            sorter: (a, b) => a.apiKey.localeCompare(b.apiKey),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => (
                <Tag
                    color={
                        text === 'Active'
                            ? 'success'
                            : 'red'
                    }
                    style={{border:'none'}}
                >
                    {text}
                </Tag>
            ),
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Create Date',
            dataIndex: 'createDate',
            sorter: (a, b) => a.createDate.localeCompare(b.createDate),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expirydate',
            sorter: (a, b) => a.expirydate.localeCompare(b.expirydate),
            sortDirections: ['ascend', 'descend'],
        },
    ];


    // // Create new API Key Button
    // const createApibutton = (e) => {
    //     e.preventDefault();
    //     const inputElement = document.getElementById("api-key-name");

    //     if (inputElement) {
    //         const inputValue = inputElement.value;
    //         setApiKeyName(inputValue);

    //         if (inputValue) {
    //             setsubmitBtn(true);
    //             setIisGenerateAPIKey(generateApiID());
    //         } else {
    //             const errorMessage = document.getElementById("api-key-error-msg");
    //             if (errorMessage) {
    //                 errorMessage.classList.remove("d-none");
    //                 errorMessage.classList.add("d-block");
    //                 setTimeout(() => {
    //                     errorMessage.classList.remove("d-block");
    //                     errorMessage.classList.add("d-none");
    //                 }, 1000);
    //             }
    //         }
    //     }
    // };


    return (
        <React.Fragment>


            {/* Api
            <Card style={{ marginBottom: customStyles.margin }} className='ApiTable'>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: customStyles.margin }} >
                    <h4 style={{ fontSize: "16px", fontWeight:'500' }} >API Keys</h4>
                </div>
                {
                    isLoading ? <Spinners setLoading={setLoading} />
                        :
                        <>
                            {apiKeyList && apiKeyList.length > 0 ?
                                <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                                    <Table
                                        rowSelection={{
                                            ...rowSelection,
                                        }}
                                        columns={columns}
                                        dataSource={(apiKeyList || []).map((keylist, index) => ({
                                            ...keylist,
                                            key: index,
                                        }))}
                                        pagination={pagination}
                                    />
                                </div>
                                : ""}
                        </>
                }
            </Card>

            <ToastContainer /> */}

        </React.Fragment>
    )
}

export default ApiTable