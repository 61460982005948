import usecustomStyles from "Common/customStyles";
import React, { useEffect, useState } from "react";
import ContentHeader from "../ContentHeader";
import { Card, Col, Row } from "antd";

import GeofenceMapComponent from "../Core/GeofenceMapComponent";
import CRUDComponent from "../../CRUD/CRUDComponent"; // Ensure the correct path
import createCRUDFunctions from "../../CRUD/CRUDFunctions";

const Geofences = ({ customerCode }) => {
  const customStyles = usecustomStyles();
  const modelName = "geofence-crud";
  const [geofences, setGeofences] = useState([]);
  const [updateItself, setUpdateItself] = useState(false);

  const { getColumns, getAllItems, addItem, updateItem, deleteItem } =
    createCRUDFunctions(modelName, customerCode);

  const newGeofenceDrawn = (newGeofence) => {
    setGeofences((prevGeofences) => [...prevGeofences, newGeofence]);
    addItem(newGeofence);
    loadData();
    setUpdateItself(!updateItself);
  };

  const loadData = async () => {
    let data = await getAllItems();
    setGeofences(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <ContentHeader headerTitle={"Geofences"} />

      <Row gutter={[24]} style={{ marginTop: "-20px" }}>
        <Col xs={24} className="gutter-row" style={{ overflowX: "auto" }}>
          <Card
            style={{ marginBottom: customStyles.margin, overflowX: "auto" }}
          >
            <CRUDComponent
              modelName={modelName}
              createCRUDFunctions={createCRUDFunctions}
              updateItself={updateItself}
              dataChangedNotifier={loadData}
            />
          </Card>
          <Card
            style={{
              marginBottom: customStyles.margin,
              marginTop: "20px",
              overflowX: "auto",
            }}
          >
            <GeofenceMapComponent
              geofences={geofences}
              addGeofence={newGeofenceDrawn}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Geofences;
