import { Button, Card, Col, Row, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import {
//   getUsers as onGetUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser,
//   deleteUser as onDeleteUser,
// } from "../../../slices/thunk";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EyeFilled,
  FolderOutlined,
  HistoryOutlined,
  PlusOutlined,
  StarOutlined,
  TagFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import usecustomStyles from "../../../Common/customStyles";
import Spinners from "../../../Common/Spinner";
import { DeleteModal } from "../../../Common/DeleteModal";
import DrawerFile from "./DrawerFile";
import UserModal from "./UserModal";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

const customStyles = usecustomStyles();
const TextMuted = styled.div`
  color: ${({ theme }) => theme.token.textLight};
`;
const TextLight = styled.div`
  color: ${({ theme }) => theme.token.textLight};
`;
const UserSide = ({ users, loading, userList, setUserList }) => {
  const dispatch = useDispatch();

  // dispatch data

  const onGetUsers = () => {};
  const onAddNewUser = (user) => {};
  const onUpdateUser = (user) => {};
  const onDeleteUser = (user) => {};

  useEffect(() => {
    onGetUsers();
  }, [dispatch]);

  const [isLoading, setLoading] = useState(loading);

  const [activeList, setActiveList] = useState("all");

  const [isEdit, setIsEdit] = useState(false);

  const [info, setInfo] = useState({
    img: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    label: "",
    tab: "",
  });
  const [isOffcanvas, setIsOffcanvas] = useState(false);

  const [user, setUser] = useState([]);

  const [fieldValue, setFieldValue] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDelete = () => {
    setDeleteModal(false);
    setUser(null);
  };

  // Delete User
  const onClickDelete = (User) => {
    setUser(User);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (user) {
      dispatch(onDeleteUser(user.id));
      setDeleteModal(false);
      setUser(null);
    }
  };

  // Add && Update

  const [modalShow, setModalShow] = useState(false);

  const toggle = useCallback(() => {
    if (modalShow) {
      setModalShow(false);
      setIsEdit(false);
      setUser(null);
      setFieldValue(null);
    } else {
      setModalShow(true);
    }
  }, [modalShow]);

  // Update User
  const handleUpdateUser = useCallback(
    (ele) => {
      const User = ele;
      setUser({
        id: User.id,
        name: User.name,
        img: User.img,
        email: User.email,
        phone: User.phone,
        country: User.country,
        label: User.label,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Filter
  const labelFilter = (ele) => {
    let filterData;
    if (ele === "all") {
      filterData = users.filter((item) => item);
    } else {
      filterData = users.filter(
        (item) => item.tab === ele || item.label === ele
      );
    }
    setActiveList(ele);
    setUserList(filterData);
  };

  const columns = [
    {
      title: "Name",
      // dataIndex: 'img',
      render: ({ img, name }) => {
        return (
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={img}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    height: "32px",
                    width: "32px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div>
                <h6 style={{ fontSize: "13px" }}>{name}</h6>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Label",
      dataIndex: "label",
      render: (text) => (
        <Tag
          color={
            text === "Business"
              ? "cyan"
              : text === "Friends"
              ? "purple"
              : text === "Family"
              ? "success"
              : "red"
          }
          style={{ border: "none" }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      render: (text) => (
        <span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Link
                href="#viewUseroffcanvas"
                style={{ color: customStyles.textMuted, marginRight: "10px" }}
                onClick={() => {
                  setInfo(text);
                  setIsOffcanvas(true);
                }}
                className="viewlist-btn"
              >
                <EyeFilled />
              </Link>
            </div>
            <div>
              <Link
                href="#addUserModal"
                style={{ color: customStyles.textMuted, marginRight: "10px" }}
                onClick={() => handleUpdateUser(text)}
              >
                <EditFilled />
              </Link>
            </div>
            <div>
              <Link
                href="#removeUserModal"
                style={{ color: customStyles.textMuted }}
                onClick={() => {
                  onClickDelete(text);
                }}
              >
                <DeleteFilled />
              </Link>
            </div>
          </div>
        </span>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Row gutter={[16]} style={{ marginTop: "-20px" }}>
        <Col xs={24} lg={5}>
          <Card style={{ marginBottom: customStyles.margin }}>
            <div>
              <Button
                type="primary"
                style={{ width: "100%", boxShadow: "none" }}
                className="w-100 addUser-modal"
                onClick={() => toggle()}
              >
                <PlusOutlined /> Add User
              </Button>
              <div
                style={{ marginTop: "20px" }}
                className="mt-3 mx-n4 px-4 User-sidebar-menu"
                data-simplebar
              >
                {/* <ul className="list-unstyled User-menu-list">
                                    <li style={{ marginBottom: '10px' }}>
                                        <Link to="#!" className={activeList === "all" ? "active Useractive" : ""} onClick={() => labelFilter("all")}>
                                            <TextMuted className='Useractiveclass'><FolderOutlined />
                                                <span data-tab="all" style={{ marginLeft: '8px' }}>All Users</span> </TextMuted></Link>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <Link to="#!" className={activeList === "frequently" ? "active Useractive" : ""} onClick={() => labelFilter("frequently")}>
                                            <TextMuted className='Useractiveclass'><HistoryOutlined /> <span style={{ marginLeft: '8px' }}>Frequently Usered</span></TextMuted>
                                        </Link>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <Link to="#!" className={activeList === "important" ? "active Useractive" : ""} onClick={() => labelFilter("important")}>
                                            <TextMuted className='Useractiveclass'><StarOutlined /> <span style={{ marginLeft: '8px' }}>Important</span></TextMuted>
                                        </Link>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <Link to="#!" className={activeList === "groups" ? "active Useractive" : ""} onClick={() => labelFilter("groups")}>
                                            <TextMuted className='Useractiveclass'><UsergroupAddOutlined /> <span style={{ marginLeft: '8px' }}>Groups</span></TextMuted>
                                        </Link>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <Link to="#!" className={activeList === "trash" ? "active Useractive" : ""} onClick={() => labelFilter("trash")}>
                                            <TextMuted className='Useractiveclass'> <DeleteOutlined /> <span style={{ marginLeft: '8px' }}>Deleted</span></TextMuted>
                                        </Link>
                                    </li>
                                </ul> */}

                <TextLight
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "16px",
                    fontSize: "13px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  Labels
                </TextLight>
                <ul className="list-unstyled User-menu-list">
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="#!"
                      className={
                        activeList === "Friends"
                          ? "active Useractive"
                          : "text-body"
                      }
                      onClick={() => labelFilter("Friends")}
                    >
                      <div
                        style={{ display: "flex" }}
                        className="Useractiveclass"
                      >
                        <TagFilled
                          style={{ color: customStyles.colorSecondary }}
                        />
                        <TextMuted
                          style={{ marginLeft: "8px" }}
                          data-label="Friends"
                        >
                          Friends
                        </TextMuted>
                      </div>
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="#!"
                      className={
                        activeList === "Family"
                          ? "active Useractive"
                          : "text-body"
                      }
                      onClick={() => labelFilter("Family")}
                    >
                      <div
                        style={{ display: "flex" }}
                        className="Useractiveclass"
                      >
                        <TagFilled
                          style={{ color: customStyles.colorSuccess }}
                        />
                        <TextMuted
                          style={{ marginLeft: "8px" }}
                          data-label="Family"
                        >
                          Family
                        </TextMuted>
                      </div>
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="#!"
                      className={
                        activeList === "Business"
                          ? "active Useractive"
                          : "text-body"
                      }
                      onClick={() => labelFilter("Business")}
                    >
                      <div
                        style={{ display: "flex" }}
                        className="Useractiveclass"
                      >
                        <TagFilled style={{ color: customStyles.colorInfo }} />
                        <TextMuted
                          style={{ marginLeft: "8px" }}
                          data-label="Business"
                        >
                          Business
                        </TextMuted>
                      </div>
                    </Link>
                  </li>
                  <li style={{ marginBottom: "12px" }}>
                    <Link
                      to="#!"
                      className={
                        activeList === "Imported"
                          ? "active Useractive"
                          : "text-body"
                      }
                      onClick={() => labelFilter("Imported")}
                    >
                      <div
                        style={{ display: "flex" }}
                        className="Useractiveclass"
                      >
                        <TagFilled
                          style={{ color: customStyles.colorDanger }}
                        />
                        <TextMuted
                          style={{ marginLeft: "8px" }}
                          data-label="Imported"
                        >
                          Imported
                        </TextMuted>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={19}>
          <div>
            <div id="recomended-jobs" className="table-card">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  <Table
                    columns={columns || []}
                    dataSource={(userList || []).map((conlist, index) => ({
                      ...conlist,
                      key: index,
                    }))}
                    bordered
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/* Delete Modal */}
      <DeleteModal
        show={deleteModal}
        handleClose={toggleDelete}
        deleteModalFunction={handleDeleteUser}
      />
      <DrawerFile
        info={info}
        isOffcanvas={isOffcanvas}
        setIsOffcanvas={setIsOffcanvas}
      />
      <UserModal
        modalShow={modalShow}
        toggle={toggle}
        User={user}
        onUpdateUser={onUpdateUser}
        onAddNewUser={onAddNewUser}
        fieldValue={fieldValue}
        isEdit={isEdit}
        dispatch={dispatch}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

export default UserSide;
