const dataBasicCircleDiagram = [
    {
        type: 'Category one',
        value: 27,
    },
    {
        type: 'Category two',
        value: 25,
    },
    {
        type: 'Category three',
        value: 18,
    },
    {
        type: 'Category four',
        value: 15,
    },
    {
        type: 'Category five',
        value: 10,
    },
    {
        type: 'other',
        value: 5,
    },
];

export { dataBasicCircleDiagram }