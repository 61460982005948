import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Function to calculate color gradient based on hour
const getSunColor = (hour) => {
  if (hour >= 5 && hour < 18) {
    const ratio = (hour - 5) / (18 - 5);
    const yellow = [255, 255, 0];
    const orange = [255, 165, 0];
    const r = Math.round(yellow[0] * (1 - ratio) + orange[0] * ratio);
    const g = Math.round(yellow[1] * (1 - ratio) + orange[1] * ratio);
    const b = Math.round(yellow[2] * (1 - ratio) + orange[2] * ratio);
    return `rgb(${r}, ${g}, ${b})`;
  }
  return "#800080"; // Purple for nighttime
};

// Function to format timestamp into hour with AM/PM
const formatHour = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHour = (hours % 12 || 12).toString().padStart(2, "0");
  return `${formattedHour} ${ampm}`;
};

// Function to format timestamp into human-readable format
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleDateString("en-US", options);
};

// Function to create a colored SVG icon with hour
const createIcon = (color, hourText) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" stroke="${color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="25" cy="25" r="15" stroke="${color}" stroke-width="3" fill="white" />
      <text x="50%" y="50%" font-size="9" text-anchor="middle" dy=".2em" fill="${color}">${hourText}</text>
    </svg>
  `;
  const url = `data:image/svg+xml;base64,${btoa(svg)}`;

  return new L.Icon({
    iconUrl: url,
    iconSize: [50, 50],
    iconAnchor: [25, 50],
  });
};

// Function to get SVG color and hour text
const getMarkerDetails = (timestamp) => {
  const date = new Date(timestamp);
  const hour = date.getHours();

  const color = getSunColor(hour);
  const hourText = formatHour(timestamp);

  return {
    color,
    hourText,
  };
};

// Component to fit map bounds
const FitBounds = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds.length) {
      const leafletBounds = L.latLngBounds(bounds);
      map.fitBounds(leafletBounds);
    }
  }, [bounds, map]);

  return null;
};

const MapComponent = ({ data }) => {
  // Calculate map center
  const center = data.length
    ? [
        data.reduce((sum, sample) => sum + sample.location_sample.latitude, 0) /
          data.length,
        data.reduce(
          (sum, sample) => sum + sample.location_sample.longitude,
          0
        ) / data.length,
      ]
    : [0, 0];

  // Calculate bounds
  const bounds = data.map((sample) => [
    sample.location_sample.latitude,
    sample.location_sample.longitude,
  ]);

  return (
    <MapContainer
      center={center}
      zoom={5} // Adjusted zoom level to fit all data
      style={{ height: "500px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {data.map((sample, index) => {
        const { color, hourText } = getMarkerDetails(sample.data_timestamp);

        return (
          <Marker
            key={index}
            position={[
              sample.location_sample.latitude,
              sample.location_sample.longitude,
            ]}
            icon={createIcon(color, hourText)}
          >
            <Popup>
              <div>
                <strong>Timestamp:</strong>{" "}
                {formatTimestamp(sample.data_timestamp)}
                <br />
                <strong>Found Venue ID:</strong> {sample.found_venue_id}
                <br />
                <strong>Coordinates:</strong>{" "}
                {sample.location_sample.latitude.toFixed(4)},{" "}
                {sample.location_sample.longitude.toFixed(4)}
              </div>
            </Popup>
          </Marker>
        );
      })}
      <FitBounds bounds={bounds} />
    </MapContainer>
  );
};

export default MapComponent;
